<template>
    <div>
      <div class="mb-10">
        <router-link :to="{ name: 'auth.login' }">
          <small class="muted--text">Back to Login</small>
        </router-link>
        <h1 title="Login" class="mb-5">Email Verification</h1>
  
        <v-alert v-if="undefined != err.message" type="error" class="mb-8" outlined dense>
          {{ err.message }}
        </v-alert>

        <v-alert v-if="isShowSuccess" type="success" class="mb-8" outlined dense>
					{{ message }}
        </v-alert>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    data() {
      return {
        isShowSuccess: false,
				message: ''
      };
    },
    computed: {
      ...mapGetters({
        err: "auth/GET_ERR",
      }),
    },
    methods: {
      ...mapActions({
        actionVerifyEmail: "auth/actionVerifyEmail",
      }),

      async verifyEmail() {
        let response = await this.actionVerifyEmail({
					id: this.$route.params.userId,
					email: this.$route.query.email,
					expiry: this.$route.query.expiry
        });

				if (response.success) {
					this.message = response.message || 'Email successfully verified.'
				}
        if (this.err.message == undefined) {
          this.isShowSuccess = true;
        }
      },
    },
		created() {
			this.verifyEmail();
		}
  };
  </script>
  
  <style></style>
  